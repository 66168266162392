/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  

  function configurer(){
      //NAVIGATION
      $('.menu-trigger').on('click', function() {
        $('#initial-header').addClass('menu-visible');
        $('#main-navigation').addClass('menu-visible');
        $('#visible-menu').addClass('menu-visible');
      });

      $('.menu-close').on('click', function() {
        $('#initial-header').removeClass('menu-visible');
        $('#main-navigation').removeClass('menu-visible');
        $('#visible-menu').removeClass('menu-visible');
      });

       //ANCHOR ANIMATION
      $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        if(the_id !== '#') {

          $('html, body').animate({
            scrollTop: $(the_id).offset().top-50
          }, 'slow');

        }
        return false;

      });

      //BTN
      $('.btn-plus').each(function() {

        $(this).wrap('<div class="btn-plus-wrapper"></div>');

        $(this).prepend("<span>+</span>");

      });

      $('input[type="submit"]').each(function() {

        $(this).wrap('<div class="btn-plus-wrapper-submit"></div>');

        $(this).parent().prepend("<span>+</span>");

      });

      $('.btn-plus-wrapper-submit').on('click touch', function() {
        $(this).parent().parent().submit();
      });

      //SLIDERS
      $('.front-slider').slick({
        slidesToShow: 1,
        infinite: true,
        dots: false,
        fade: true,
        arrows: true,
        autoplay: true,
        appendArrows: $('.front-slider-nav')

      });

      $('.home-realisations-slider').slick({
        slidesToShow: 3,
        infinite: false,
        dots: false,
        arrows: false,
        responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.5,
          }
        }
      ]
      });

      /*$('.realisation-image-slider').slick({
        slidesToShow: 1,
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
        lazyLoad: 'ondemand',
      });*/

      $('.slider-trigger').on('click touch', function() {
        var selectedId = $(this).attr('data-slider-id');

        openLightBox(selectedId);
      });
      $('.close-lightbox').on('click touch', function() {
        var selectedId = $(this).parent().parent().attr('data-slider-id');

        closeLightBox(selectedId);
      });

      //ACCORDEONS
      $('.accordeon .title, .accordeon .accordeon-toggle').on('click touch', function() {

        if($(this).hasClass('title')){
          var parent = $(this).parent().parent();
        }else {
          var parent = $(this).parent();
        }

        parent.toggleClass('open');
        parent.find('.left-col, .right-col').slideToggle();

        if(parent.hasClass('open')){
          setTimeout(function() {
            parent.find('.content').css('opacity', '1');
          }, 500); 
        }else {
          parent.find('.content').css('opacity', '0');
        }        

      });

      //FORM
      if($(window).width() > 1024){

        $('input[type="file"]').wrap("<div class='file-wrapper'></div>");

        $('.file-wrapper').each(function() {
          var fileName = $(this).find('input[type="file"]').attr('name');

          var svg = '<svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.56 89.86"><defs><style>.cls-1{fill:#fff;}</style></defs><g id="Calque_2" data-name="Calque 2"><polygon class="cls-1" points="0 53.02 18.7 53.02 18.7 71.16 78.42 71.44 77.86 53.02 96.56 53.02 96.56 89.86 0 89.86 0 53.02"/><polygon class="cls-1" points="18.14 29.58 29.58 29.58 29.58 65.58 66.42 65.58 66.42 29.58 78.42 29.58 48.28 0 18.14 29.58"/></g></svg>';
          
          if($("html").attr('lang') == "en-US"){
            $(this).prepend('<label class="download-bt" for="'+fileName+'"><p>Add an attachmentz</p>'+svg+'</label>');
          }else {

            var idField = $(this).find('input[type="file"]').attr('id');

            if(idField == 'file-job'){
              $(this).prepend('<label class="download-bt" for="'+fileName+'"><p>Joindre votre CV (optionnel)'+svg+'</p></label>');
            }else {
              $(this).prepend('<label class="download-bt" for="'+fileName+'"><p>Joindre un document (optionnel)'+svg+'</p></label>');
            }

            
          }

          $(this).find('input[type="file"]').slideUp();
        });

        $('input[type="file"]').on('change', function() {

          var fullPath = $(this).val();

          var svgRemove = '<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.181874 3.39532L0.604727 3.81818L2.00006 2.42285L3.39538 3.81818L3.81824 3.39532L2.42291 1.99999L3.81824 0.604666L3.39538 0.181813L2.00006 1.57714L0.604727 0.181813L0.181874 0.604666L1.5772 1.99999L0.181874 3.39532Z" fill="#000"/></svg>';

          if (fullPath) {
              var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
              var filename = fullPath.substring(startIndex);
              if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                  filename = filename.substring(1);
              }

              $(this).parent().append('<div class="active-file"><p>'+filename+'</p><span class="remove">'+svgRemove+'</span></div>')
          }

          var currentInput = $(this);

          $('.active-file .remove').on('click', function() {
            currentInput.val('');
            currentInput.parent().find('.active-file').remove();
          });

        });
      }

      $('body').addClass('loaded');

      var s = skrollr.init({
        forceHeight: false
      });

      if (s.isMobile()) {
        s.destroy();
      }

  }

  window.onload = configurer;

  //Gestion menu
  $(window).on('scroll', function() {

    var initialMenuHeight = $('#initial-header').outerHeight();

    scrollPosition = $(this).scrollTop();
    if (scrollPosition >= initialMenuHeight) {
      $('#visible-menu').removeClass('hidden');
    }else {
      $('#visible-menu').addClass('hidden');
    }
  });

  function openLightBox(lbId) {

    $('body').addClass('no-scroll');

    $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"]').fadeIn();

    setTimeout(function() {

      $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"] .realisation-slider-image-wrapper').css('opacity', '1');

      $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"] .realisation-image-slider').slick({
        slidesToShow: 1,
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
        lazyLoad: 'ondemand',
      });


    }, 400);

  }

  function closeLightBox(lbId) {

    $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"] .realisation-slider-image-wrapper').css('opacity', '0');

    setTimeout(function() {

      $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"] .realisation-image-slider').slick('unslick');

      $('.realisation-slider-lightbox[data-slider-id="'+lbId+'"]').fadeOut();

      $('body').removeClass('no-scroll');

    }, 400);

  }


  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
